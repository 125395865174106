<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('signin.singin') }}</h1>
        </b-col>
        <b-col cols="12" class="px-4 py-5">
          <h2>{{ info }}</h2>
          <div v-if="info != ''">
            <b-link @click="clickLogin">{{$t('signin.login-here')}}</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name : "SignUpConfirm",
  data: function() {
    return {
      hash : this.$route.params.hash,
      info: ""
    }
  },
  created: async function() {
    // console.clear();
    // console.log(this.hash);
    
    let myLang = null;
    const langShortName = "";
    if (window.sessionStorage.getItem("dmn_lng") !== null) {
      // console.log("Postavljen jezik iz sesije");
      myLang = this.decodeJSON(window.sessionStorage.getItem("dmn_lng"));
      this.setNewLanguage(myLang);
    } else {
      this.$http.get("/api/lang/getshort/en").then(r => {
        // console.log("Dobavljam jezik sa servera");
        myLang = r.data;
        this.setNewLanguage(myLang);
        //console.log("Jezik je postavljen: " + myLang.IANA);
      }).catch(e => { this.handleError(e); })
    }

    this.$http.get("/api/users/sign-up/confirm/" + this.hash).then(r => {
      if (r.data === "OK") {
        this.info =  this.$t('signin.successfully-activated');
      }
    }).catch(e => {
      var message = this.handleError(e);
    })
  },
  methods: {
    clickLogin: function() {
      this.$root.$emit("bv::show::modal", "loginModal")
    }
  }
}
</script>

<style></style>

